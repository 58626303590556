import { collection, getDocs, orderBy } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../../firebase";
import MomentCard from "./MomentCard"
import Typewriter from "typewriter-effect";
import useHead from "../layout/useHead";
import {motion} from "framer-motion"
import { Helmet } from "react-helmet";

function Moments() {
  var qt=" \" Coming together is a beginning, staying together is progress,";
  var qt1=" and working together is success \" ";

  const [moments,setMoments] = useState([]);
  const fetchMoments = async () => {
    await getDocs(collection(db, "moments"),orderBy("title")).then((qs) => {
      const data = qs.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      setMoments(data);
    });
  };
  useHead(`E-KART | MOMENTS`)
  useEffect(() => {
    window.scrollTo(0, 0);
      fetchMoments();
  },[])
  return (
    <motion.div initial={{width:0,opacity:0}} animate={{width:"100%", opacity:1 , transition:{
      duration:0.4,
      ease:"easeInOut"
    }}} exit={{x:window.innerWidth , opacity:0}} className="bg-black w-full  min-h-screen h-fit md:p-20 max-md:py-[4rem] sm:max-md:px-[3rem] max-sm:pl-[2rem] max-sm:pr-[2rem]">
      <div className="w-full h-fit mb-3">
        <Helmet>
          <meta name="description" content="Come and join with us to see our Adventures and Journey" />
        </Helmet>
            <div className=" w-fit px-3  h-fit flex flex-col pt-3">
              <h1 className="text-white font-bold text-2xl tracking-wide"> 
              <Typewriter
                onInit={(typewriter)=> {
                        typewriter
                        .typeString(qt)
                        .pauseFor(500)
                        // .deleteAll()
                        .typeString(qt1)
                        .start();
                      }}
              />
              </h1>
              <p className="text-gray-400 font-light m-0 mt-2 mb-3 self-end">– Henry Ford</p>
              </div>
                    
      </div>
      <div className="w-full h-fit flex flex-col mt-4 ">
              {moments.map((moment,index) => (
                  <MomentCard no={index} title={moment.title} content={moment.content} date={moment.date} contentitle={moment.contentitle}/>
              ))}
              
             
               

      </div>
    </motion.div>
  );
}

export default Moments;

import React from 'react'
import  DateRangeRoundedIcon  from '@mui/icons-material/DateRangeRounded';
import moment from 'moment';
import {motion} from "framer-motion"
function PostDetail({post}) {
    const getContentFragment = (index, text, obj, type) => {
        let modifiedText = text;
    
        if (obj) {
          if (obj.bold) {
            modifiedText = (<b key={index}>{text}</b>);
          }
    
          if (obj.italic) {
            modifiedText = (<em key={index}>{text}</em>);
          }
    
          if (obj.underline) {
            modifiedText = (<u key={index}>{text}</u>);
          }
        }
    
        switch (type) {
          case 'heading-three':
            return <h3 key={index} className="text-xl font-semibold mb-4">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h3>;
          case 'paragraph':
            return <p key={index} className="mb-6 ">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</p>;
          case 'heading-four':
            return <h4 key={index} className="text-md font-semibold mb-4">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h4>;
          case 'image':
            return (
              <img
                key={index}
                alt={obj.title}
                height={obj.height}
                src={obj.src}
                className="rounded-md w-[90%]"
              />
            );
          default:
            return modifiedText;
        }
      };
      
  return (
    <motion.div initial={{scale:0,opacity:0}} animate={{scale:1, opacity:1 , transition:{
      duration:0.4,
      ease:"easeInOut"
    }}} className='bg-[#212121] w-full h-full shadow-lg lg:p-8 pb-12 mb-8 rounded-lg'>
      <div className='relative overflow-hidden shadow-md mb-6'
      >
            <img src={post?.featuredImage?.url}
            alt={post?.title}
            className="object-top h-full w-full rounded-t-lg"
            />
      </div>
      <div className='px-4 lg:px-0 h-full'>
        <div className='flex items-center max-md:justify-between  mb-8 w-full h-full '>
        <div className="flex items-center mb-4 h-full  lg:mb-0 w-[60%] lg:w-auto mr-8 ">
          <img
            alt={post?.author?.name}
            className="align-middle w-[40px] h-[40px] rounded-full"
            src={post?.author?.photo?.url}
          />
          <p className="inline align-middle text-gray-200 ml-2 font-medium text-lg">{post?.author?.name}</p>
        </div>
        <div className="font-medium w-[40%] h-full flex items-center  text-gray-200">
        <DateRangeRoundedIcon
              sx={{
                color: "skyblue",
              }}
              fontSize="medium"
            />
          <span className="align-middle ml-2">{moment(post?.createdAt).format('MMM DD, YYYY')}</span>
        </div>
        </div>
              <h1 className='mb-8 text-3xl font-semibold text-white '>{post?.title}</h1>
              <div className="text-center flex flex-col items-center text-white px-3">
              {post?.content?.raw.children.map((tobj,index) =>{
                const children = tobj.children.map((item,itemIndex) => getContentFragment(itemIndex,item.text))
                return getContentFragment(index, children,tobj,tobj.type)
              } )}
              </div>
              
      </div>
    </motion.div>
  )
}

export default PostDetail

import React from "react";
import { useRef } from "react";
import { useState } from "react";

function ScrollButton() {
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef();
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollFunc = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
  };
  window.addEventListener("scroll", toggleVisible);
  return (
    <button
      ref={buttonRef}
      onClick={scrollFunc}
      className={
        visible
          ? `fixed px-6 py-4 z-20 bg-[#212121] border-[1px] hover:border-[2.5px] border-red-500 hover:bg-gray-100 duration-500 text-red-500 rounded-full  bottom-6 right-6`
          : `hidden`
      }
    >
      <i className="fa-solid fa-arrow-up text-base"></i>
    </button>
  );
}

export default ScrollButton;

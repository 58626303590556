import React, { useEffect, useState } from "react";

function MyNewsletterForm({ status, message, onValidated }) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const submit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf("@") > -1 &&
      onValidated({
        MERGE0: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
  };
  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);
  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  };
  return (
    <form className=" xl:w-[30%] lg:max-xl:w-[41%] h-fit py-7 px-8 bg-[#212121] rounded-lg shadow-md md:max-lg:w-[50%] flex flex-col justify-center max-md:w-[75%] " onSubmit={(e) => submit(e)}>
      <h3 className="text-white my-2 font-bold text-2xl">JOIN OUR NEWSLETTER</h3>
      <div className="w-full h-[3px] rounded-md bg-red-500 mb-3 "></div>
      {status === "sending" && (
        <div className="mc__alert mc__alert--sending">sending...</div>
      )}
      {status === "error" && (
        <div
        className=" h-fit bg-red-500 rounded-md px-4 py-3 text-black "
        dangerouslySetInnerHTML={{ __html: message }}
      />
      )}
      {status === "success" && (
        <div
        className=" h-fit bg-green-400 rounded-md px-4 py-3 text-black "
        dangerouslySetInnerHTML={{ __html: message }}
      />
      )}
       
      <div className="w-full my-2">
      <h5 className="text-lg font-semibold mb-0 text-white tracking-wider">FIRST NAME</h5>
        <input
          type="text"
          value={firstName}
          isrequired
          className="w-full my-1 p-2 rounded-md outline-none  "
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="enter your First Name"
        />
      </div>
      <div className="w-full my-2">
      <h5 className="text-lg font-semibold mb-0 text-white tracking-wider">LAST NAME</h5>
      <input
          type="text"
          value={lastName}
          isrequired
          className="w-full my-1 p-2 rounded-md outline-none  "
          onChange={(e) => setLastName(e.target.value)}
          placeholder="enter your Last Name"
        />
      </div>
      <div className="w-full my-2">
      <h5 className="text-lg font-semibold mb-0 text-white tracking-wider">EMAIL</h5>
      <input
          type="email"
          value={email}
          isrequired
          className="w-full my-1 mb-2 p-2 rounded-md outline-none"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="enter your email"
        />
      </div>
      
      <button type="submit" className="bg-red-500 p-2 mt-2 text-white font-semibold tracking-wider rounded-md hover:">SUBMIT</button>
    </form>
  );
}

export default MyNewsletterForm;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import { loadUser } from "../../reducers/auth";
import { removeAlert, setAlert } from "../../reducers/alert";
import { v4 as uuidv4 } from "uuid";
import useHead from "../layout/useHead";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRef } from "react";
import { useEffect } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";

import { motion } from "framer-motion";
function Register() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  useHead(`E-KART | REGISTER`);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const auth = getAuth();
  const imageRef = useRef();
  const displayAlert = (msg, type) => {
    const id = uuidv4();
    dispatch(setAlert({ msg, type, id }));
    setTimeout(() => dispatch(removeAlert(id)), 5000);
  };
  const navigate = useNavigate();
  const registerUser = () => {
    if (phone.length !== 10 || name.length === 0) {
      displayAlert("Enter the valid Credentials", "error");
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (uc) => {
          dispatch(
            loadUser({
              token: uc._tokenResponse.refreshToken,
            })
          );
          displayAlert("Account Registered", "success");
          navigate("/");

          setEmail("");
          setPassword("");
        })
        .catch((err) => {
          if (err.code === "auth/email-already-in-use") {
            displayAlert("Email Already in Use", "error");
          }
          if (err.code === "auth/invalid-email") {
            displayAlert("Enter an proper email", "error");
          }
          if (err.code === "auth/weak-password") {
            displayAlert("Enter a Strong Password", "error");
          }
        });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getDownloadURL(ref(storage, "/login.png")).then((url) => {
      imageRef.current.src = url;
    });
  }, []);
  return (
    <motion.div
      initial={{ width: 0, opacity: 0 }}
      animate={{
        width: "100%",
        opacity: 1,
        transition: {
          duration: 0.4,
          ease: "easeInOut",
        },
      }}
      exit={{ x: window.innerWidth, opacity: 0 }}
      className="flex h-screen"
    >
      <div className="flex flex-col justify-center w-full h-full bg-black  md:w-3/5 md:p-8">
        <button
          onClick={() => {
            navigate("/login");
          }}
          className=" px-6 py-3  absolute top-3 left-2 hover:text-red-500 text-white  w-8 flex items-center justify-center transition-colors"
        >
          {" "}
          <ArrowBackIcon />
        </button>
        <div className="w-3/5 h-fit max-sm:shadow-lg md:bg-transparent self-center flex flex-col p-4 rounded-lg bg-[#212121] lg:w-3/4 max-md:w-[80%]  md:w-full  max-sm:p-8   ">
          <h1 className="text-2xl font-bold text-white">Register</h1>
          <p className="text-red-500 ml-0">
            Enter your credentials for your account
          </p>
          <div className=" my-2 mb-1 h-fit p-2 pl-0 flex flex-col font-bold font-sans  ">
            <label className="text-white text-lg">NAME</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="email"
              className="outline-none border border-zinc-300 p-2 rounded-md h-full  mt-1  "
            />
          </div>
          <div className=" my-2 mb-1 h-fit p-2 pl-0 flex flex-col font-bold font-sans  ">
            <label className="text-white text-lg">PHONE NO</label>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="email"
              className="outline-none border border-zinc-300 p-2 rounded-md h-full  mt-1  "
            />
          </div>
          <div className=" my-2 mb-1 h-fit p-2 pl-0 flex flex-col font-bold font-sans  ">
            <label className="text-white text-lg">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="outline-none border border-zinc-300 p-2 rounded-md h-full  mt-1  "
            />
          </div>
          <div className=" my-2 mt-0 h-fit p-2 pl-0 flex flex-col font-bold font-sans  ">
            <label className="text-white text-lg">Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="outline-none rounded-md border border-zinc-300  h-full mt-1 p-2  "
            />
          </div>
          <button
            onClick={registerUser}
            className=" flex items-center justify-center   rounded-lg w-20 p-2 text-white  bg-red-400 self-end mt-1 mr-2 "
          >
            SIGN UP
          </button>
        </div>
      </div>
      <div className="bg-black hidden h-full  md:block md:w-2/5">
        <img
          ref={imageRef}
          src=""
          alt=""
          className="w-full h-full overflow-hidden object-cover "
        />
      </div>
    </motion.div>
  );
}

export default Register;

import React, { useRef, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { Tooltip } from "@mui/material";
import {storage} from "../../firebase"
import { useEffect } from "react";
import "../../styles/MomentCard.css"
import {motion} from "framer-motion"
function MomentCard({title,content,date,contentitle,no}) {
  const [imageUrl,setImageUrl] = useState("");
  const pinRef = useRef();
  const contentRef = useRef();
  const containerRef = useRef();
  const [open, setOpen] = useState(false);
  const showContent = () => {
    contentRef.current.classList.remove("hidden");
    setOpen(true);
  };
  const hideContent = () => {
    contentRef.current.classList.add("hidden");
    setOpen(false);
  };
  const fetchImages = async (name) => {
    const storageRef = ref(storage, `moments/${name}.jpg`);
    getDownloadURL(storageRef).then((dURL) => {
      setImageUrl(dURL);
    });
  };
  useEffect(() => {
      fetchImages(`${title}`)
  },[title])
  return (
    <motion.div initial={{scale:0,opacity:0}} animate={{scale:1,opacity:1 , transition:{
      delay:(0.4+(no-(no*(60/100)))),
      duration:0.3
    }}} className=" w-full  h-[16rem]  flex relative ">
      <div className="flex items-center justify-center  w-[20%] p-4 h-full max-md:hidden">
        <h1 className="text-white font-bold text-3xl tracking-wider">{title}</h1>
      </div>

      <div className="h-full w-[80%] max-md:w-full  flex">
        <div className="h-full w-[2px] bg-white   flex items-center justify-center">
          <span class="flex h-4 w-4 relative">
            <span
              ref={pinRef}
              class=" absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"
            ></span>
            <span
              onClick={open ? hideContent : showContent}
              onMouseEnter={() => {
                pinRef.current.classList.add("animate-ping");
              }}
              onMouseLeave={() => {
                pinRef.current.classList.remove("animate-ping");
              }}
              class="relative inline-flex rounded-full h-4 w-4 cursor-pointer transition-color ease-in duration-200 bg-red-500 border-2 border-white "
            ></span>
          </span>
        </div>
        <div className=" w-full p-2 flex  items-center">
          <div
            onClick={open ? hideContent : showContent}
            className="w-[40%] max-sm:w-[45%] z-30 h-full aspect-square  mx-3 mr-2 rounded-xl rounded-r-2xl shadow-xl cursor-pointer relative"
          >
            {open ? (
              <div
                className="w-full h-full relative "
             
              >
                <img
                  src={imageUrl}
                  alt=""
                  className="w-full h-full object-cover rounded-xl  rounded-r-2xl z-0 absolute"
                />
               <div
                    ref={containerRef}
                    className="w-full h-full bg-gradient-to-tr from-black opacity-80 to-transparent  absolute md:hidden top-0 z-30 rounded-xl p-3 pb-4 pl-5 rounded-r-2xl flex items-end justify-start"
                  >
                    <h1 className="text-white font-semibold text-lg">{title}</h1>
                  </div>
              </div>
            ) : (
                

                
              <Tooltip title={`click to expand the moment description`} arrow placement="right">
                <div
                  className="w-full h-full "
                  onMouseEnter={() => {
                    containerRef.current.classList.remove("hidden");
                  }}
                  onMouseLeave={() => {
                    containerRef.current.classList.add("hidden");
                  }}
                >
                
                  <img
                    src={imageUrl}
                    alt=""
                    className="w-full h-full object-cover rounded-xl rounded-r-2xl absolute z-0"
                  />
              <div
                    ref={containerRef}
                    className="w-full h-full bg-gradient-to-tr from-black opacity-80 to-transparent hidden  md:hidden absolute top-0 z-30 rounded-xl p-3 pb-4 pl-5 rounded-r-2xl flex items-end justify-start"
                  >
                    <h1 className="text-white font-semibold text-lg">{title}</h1>
                  </div>
                </div>
                  </Tooltip>
            )}
          </div>
          <div
            ref={contentRef}
            className="hidden  max-md:w-[60%] max-sm:w-[55%] md:max-lg:w-[45%] lg:max-xl:w-[40%] xl:w-[35%]  z-20 relative -left-8 bg-[#212121] text-white rounded-r-2xl h-full pl-10 pt-2 pr-5 transition duration-200 ease-in-out "
          >
            <div className="w-full h-fit py-1  m-0">
              <h1 className="font-semibold text-xl max-md:text-lg tracking-wider ">
                {contentitle}
              </h1>
              <div className="w-full h-[2px] bg-red-500 "></div>
            </div>
            <p className="m-0 sm:max-md:text-[0.81rem] max-sm:text-[0.65rem] font-semibold overflow-y-scroll scroll-manipulator text-[0.85rem] lg:text-lg">
              {content} 
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default MomentCard;



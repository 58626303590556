import {createSlice } from "@reduxjs/toolkit"

export const alertSlice = createSlice({
    name:"alert",
        initialState:[],
        reducers:{
            setAlert : (state,action)=>{
                const len = state.length;
                if(len>=1 && action.payload.msg !== state[len-1].msg){
                    state.push(action.payload);
                }else if(len === 0){
                    state.push(action.payload);                
                }
            },
            removeAlert : (state,action) => {
               return state.filter((alert) => alert.id !== action.payload);
            }
        }

})

export const {setAlert , removeAlert} = alertSlice.actions;

export default alertSlice.reducer;
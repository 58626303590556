import React from "react";
import { useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import TwitterIcon from '@mui/icons-material/Twitter';
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import { useEffect } from "react";
import Membercard from "../members/Membercard";
import { collection, where, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import useHead from "../layout/useHead";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
function EventComponent({
  title,
  desc,
  eventdesc,
  brochureStatus,
  eventStatus,
  insta,
  fb,
  linkedin,
  yt,
  twit
}) {
  const [image, setImage] = useState({});
  const [teamcaptain, setTeamCaptain] = useState({});
  const storage = getStorage();
  const [brochureUrl, setBrochureUrl] = useState("");
  const listRef = ref(storage, `${title}/`);
  const fetchImageUrl = async (path) => {
    let key = "";
    let value = "";
    const storageRef = ref(storage, `${path}`);
    await getDownloadURL(storageRef).then((dURL) => {
      value = dURL;
    });
    await getMetadata(storageRef).then((data) => {
      key = data.name.split(".")[0];
    });
    setImage((image) => ({ ...image, [`${key}`]: value }));
  };
  // Find all the prefixes and items.
  const fetchCaptain = async () => {
    const captainsRef = collection(db, "captain");
    const searchQuery = query(captainsRef, where(`leads`, "==", title));
    await getDocs(searchQuery).then((cap) => {
      const data = cap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTeamCaptain(data[0]);
    });
  };
  const fetchBrochure = () => {
    getDownloadURL(ref(storage, `/${title}.pdf`)).then((url) => {
      setBrochureUrl(url);
    });
  };
  const fetchImages = async () => {
    await listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          fetchImageUrl(itemRef._location.path);
        });
      })
      .catch((error) => {});
  };
  useHead(`TEAMS | ${title}`);
  useEffect(() => {
    window.scrollTo(0, 0);
    setImage({});
    fetchImages();
    fetchCaptain();
    fetchBrochure();
  }, [title]);

  return (
    <motion.div
      initial={{ width: 0, opacity: 0 }}
      animate={{
        width: "100%",
        opacity: 1,
        transition: {
          duration: 0.4,
          ease: "easeInOut",
        },
      }}
      exit={{ x: window.innerWidth, opacity: 0 }}
      className="w-full min-h-screen h-full text-black bg-black pt-12 md:max-lg:px-16 sm:max-md:px-8 max-sm:px-3 lg:px-32  xl:px-[15rem]"
    >
      <Helmet>
        <meta name="description" content={`Meet the Crew  ${title} led by ${teamcaptain.firstName} ${teamcaptain.lastName}`} />
      </Helmet>
      <motion.div
        initial={{ scale: 0 }}
        animate={{
          scale: 1,
          transition: {
            delay: 0.4,
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ scale: 0 }}
        className=" w-full h-full flex flex-col bg-black rounded-md px-6 py-3 "
      >
        <h1 className="font-bold text-[3rem] max-md:text-[1.875rem] text-white tracking-wide  mb-1">
          {title}
        </h1>
        <div className="w-full h-[2px] bg-red-600 mb-4 "></div>
        <img
          src={image["image 0"]}
          alt=""
          className="w-full h-[30rem] max-md:h-[20rem] object-fill rounded-md border-gray-400 self-center shadow-lg"
        />
        <div className="w-full h-fit my-3  flex max-sm:flex-col">
          <div className=" flex flex-col sm:max-md:w-[45%] w-[23%] max-sm:w-full  h-fit min-h-[19rem] mr-5  pt-4 pb-3">
            {/* <h1 className="text-2xl font-bold  max-sm:text-[1.8rem]">The Team is lead By </h1> */}

            <div className="w-full ml-1 h-[19rem] ">
              <Membercard
                key={teamcaptain.id}
                firstname={teamcaptain.firstName}
                lastname={teamcaptain.lastName}
                email={teamcaptain.email}
                department={teamcaptain.Department}
                desig={teamcaptain.Designation}
                dob={teamcaptain.DOB}
                phone={teamcaptain.Contact}
                instagramLink={teamcaptain.instagramLink}
                linkedinLink={teamcaptain.linkedinLink}
              />
            </div>
          </div>
          <div className="w-[79%] max-sm:w-full sm:px-4 py-[2rem]  ">
            {/* <p className="m-0 my-2 text-2xl font-bold  px-2  mt-3">About the team</p> */}
            <p className="m-0 my-2 text-lg font-bold px-2 text-white text-justify mt-3">
            &nbsp;  &nbsp;  &nbsp;  &nbsp;  {desc}
            </p>
          </div>
        </div>
        <div className="  w-full h-fit p-4 sm:max-md:p-1 rounded-lg">
          <div className="w-fit h-fit pr-3">
            <h1 className="text-white font-bold text-2xl border-b-2 border-red-500 ">
              SOCIAL LINKS
            </h1>
          </div>
          <div className="w-full  h-fit text-sm max-sm:flex-col  flex">
            <div className="sm:max-md:w-fit sm:max-md:flex max-sm:w-full md:w-fit md:flex items-center h-fit">
              {insta ? (
                <a href={`${insta}`} target="_blank" rel="noreferrer">
                  <div className="sm:max-md:text-xs sm:max-md:px-1 sm:max-md:py-2  mem-social-links-instagram transition flex text-white items-center my-2  justify-start h-fit w-fit rounded-md  py-3 px-3 ">
                    <InstagramIcon />
                    <p className="font-bold ">Instagram</p>
                  </div>
                </a>
              ) : (
                ""
              )}
              {linkedin ? (
                <a href={`${linkedin}`} target="_blank" rel="noreferrer">
                  <div className="sm:max-md:text-xs sm:max-md:px-1 sm:max-md:py-2  mem-social-links-linkedin transition flex items-center my-2  text-white  justify-start h-fit w-fit rounded-md  py-3 px-3 ">
                    <LinkedInIcon />
                    <p className="font-bold ">Linkedin</p>
                  </div>
                </a>
              ) : (
                ""
              )}
            </div>

            <div className="sm:max-md:w-fit sm:max-md:flex  h-fit  sm:w-[50%] md:flex md:w-full items-center max-sm:w-full md:justify-start ">
              {yt ? (
                <a href={`${yt}`} target="_blank" rel="noreferrer">
                  <div className="sm:max-md:text-xs sm:max-md:px-1 sm:max-md:py-2   transition flex text-white items-center my-2  justify-start h-fit w-fit rounded-md hover:bg-red-600  py-3 px-4 ">
                    <YouTubeIcon />
                    <p className="font-bold ">Youtube</p>
                  </div>
                </a>
              ) : (
                ""
              )}
              {fb ? (
                <a href={`${fb}`} target="_blank" rel="noreferrer">
                  <div className="sm:max-md:text-xs sm:max-md:px-1 sm:max-md:py-2  mem-social-links-facebook transition flex text-white items-center my-2  justify-start h-fit w-fit rounded-md py-3 px-4 ">
                    <FacebookIcon />
                    <p className="font-bold ">Facebook</p>
                  </div>
                </a>
              ) : (
                ""
              )}
               {twit ? (
                <a href={`${twit}`} target="_blank" rel="noreferrer">
                  <div className="sm:max-md:text-xs sm:max-md:px-1 sm:max-md:py-2  transition flex text-white items-center my-2 hover:bg-[#52a7e8] justify-start h-fit w-fit rounded-md py-3  px-4 ">
                    <TwitterIcon />
                    <p className="font-bold ">TWITTER</p>
                  </div>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {eventStatus ? (
          <>
            {" "}
            <div className=" w-full h-fit mt-2 p-3 ">
              <h1 className="font-bold text-2xl mb-2 text-white">
                About The Event
              </h1>
              <p className="m-0 my-1 text-white">{eventdesc}</p>
            </div>
            <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1  gap-4 p-2 mb-2">
              <img
                src={image["image 1"]}
                alt=""
                className="md:col-span-2 w-full h-[15rem] md:max-h-[15rem] rounded-md hover:z-99 hover:scale-125 transition-all"
              />
              <img
                src={image["image 2"]}
                alt=""
                className="w-full h-[15rem] rounded-md hover:z-99 hover:scale-125 transition-all"
              />
              <img
                src={image["image 3"]}
                alt=""
                className="w-full h-[15rem] rounded-md hover:z-99 hover:scale-125 transition-all"
              />
              <img
                src={image["image 4"]}
                alt=""
                className="w-full h-[15rem] rounded-md hover:z-99 hover:scale-125 transition-all"
              />
              <img
                src={image["image 5"]}
                alt=""
                className="sm:max-md:col-span-2  w-full h-[15rem] max-md:max-h-[15rem] rounded-md hover:z-99 hover:scale-125 transition-all"
              />
            </div>
          </>
        ) : (
          ""
        )}

        {brochureStatus ? (
          <div className=" py-2  flex flex-col ">
            <p className="font-semibold text-sm text-white">
              Click below to Download the brochure
            </p>
            <a
              href={brochureUrl}
              target="_blank"
              rel="noreferrer"
              className="self-start ml-3 my-3 hover:bg-gray-200 px-4 py-2 text-white   bg-[#212121] hover:text-black rounded-md hover:shadow-red-500 flex items-center font-medium hover:shadow-lg transition-shadow"
            >
              Download Brochure{" "}
              <div className="ml-1 text-red-500">
                <DownloadRoundedIcon />
              </div>
            </a>
          </div>
        ) : (
          ""
        )}
      </motion.div>
    </motion.div>
  );
}

export default EventComponent;

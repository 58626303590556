import { Avatar } from "@mui/material";
import React from "react";
import {motion} from "framer-motion"
function Author({ author }) {
  return (
      <motion.div initial={{scale:0,opacity:0}} animate={{scale:1 ,opacity:1, transition:{
        duration:0.4,
        ease:"easeInOut"
      }}} className="flex items-center flex-col justify-center text-center mt-20 mb-8 p-12 relative rounded-lg bg-[#212121] bg-opacity-20">
        <div className=" absolute -top-12">
                <Avatar alt={author?.name}
                  sx={{
                    height:"80px",
                  width:"80px"}}
                  className="align-middle rounded-full"
                  src={author?.photo?.url}

                />
        </div>
        <h3 className="text-white my-4 text-xl font-bold">{author?.name}</h3>
        <p className="text-white  text-ls">{author?.bio}</p>
      </motion.div>
  );
}

export default Author;




/* <img
       
       alt={author?.name}
       height="100px"
       width="100px"
       className="align-middle rounded-full"
       src={author?.photo?.url}
     /> */
import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    isAuthenticated: false,
  },
  reducers: {
    loadUser: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
    },
    removeUser: (state) => {
      state.isAuthenticated = false;
      state.token = null;
    },
  },
});

export const { loadUser, removeUser } = authSlice.actions;

export default authSlice.reducer;

import React from "react";
import { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import ScrollButton from "./ScrollButton";

function Layout({ children }) {
  useEffect(() => {
      window.scrollTo(0,0);
  },[children])
  return (
    <div className="w-full h-full bg-black">
      <Header />

      {children}
      <ScrollButton />

      <Footer />
    </div>
  );
}

export default Layout;

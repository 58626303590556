import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {motion} from "framer-motion"
const BlogCard = ({
   post,no
}) => {
    return(
      <motion.div initial={{scale:0}} animate={{scale:1 , transition:{
        delay:(0.6+(no-(no*(70/100)))),
        duration:0.3
      }}} className="bg-[#212121] shadow-lg rounded-lg p-0 lg:p-8  pb-12 mb-8">
    
      <div className="relative overflow-hidden shadow-md pb-80 mb-6">
        <img src={post?.node?.featuredImage?.url} alt="" className="object-top absolute h-80 w-full object-cover  shadow-lg rounded-t-lg lg:rounded-lg" />
      </div>
  
      <h1 className="transition duration-200 text-white text-center mb-8 cursor-pointer hover:text-red-500 text-3xl max-md:text-[1.5rem] max-sm:text-[1.1rem] px-3 font-semibold">
        <Link to={`/post/${post?.node?.slug}`}>{post?.node?.title}</Link>
      </h1>
      <div className="block lg:flex text-center items-center justify-center mb-8 w-full">
        <div className="flex items-center justify-center mb-4 lg:mb-0 w-full lg:w-auto mr-8 ">
          <img
    
            alt={post?.node?.author?.name}
           
            className="w-[35px] h-[30px] align-middle rounded-full"
            src={post?.node?.author?.photo?.url}
          />
          <p className="inline align-middle text-gray-200 ml-2 font-medium text-lg">{post?.node?.author?.name}</p>
        </div>
        <div className="font-medium text-gray-700">
        <DateRangeRoundedIcon
              sx={{
                color: "skyblue",
              }}
              fontSize="medium"
            />
          <span className="align-middle text-gray-200">{moment(post?.node?.createdAt).format('MMM DD, YYYY')}</span>
        </div>
      </div>
      <p className="text-center text-lg text-gray-200 font-normal px-4 lg:px-20 mb-8">
        {post?.node?.excerpt}
      </p>
      <div className="text-center">
        <Link to={`/post/${post?.node?.slug}`}>
          <span className="transition duration-500 ease max-sm:px-6 transform hover:-translate-y-1 inline-block bg-red-600 text-lg font-medium rounded-full text-white px-8 py-3 cursor-pointer">Continue Reading</span>
        </Link>
      </div>
    </motion.div>
 ) };

export default BlogCard







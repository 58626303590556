import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import store from "./store"
import {Provider} from "react-redux"
import { persistStore } from 'redux-persist';
import App from './App';
import {BrowserRouter} from "react-router-dom"
import { PersistGate } from 'redux-persist/integration/react';
let persistor = persistStore(store)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    <BrowserRouter>
    <React.StrictMode>
    <App />

    </React.StrictMode>
  </BrowserRouter>
    </PersistGate>
    
  </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import React from 'react'
import { useState } from 'react'
import { storage } from '../../firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect } from 'react';
import { motion } from "framer-motion";
function Sponser({name , link,index}) {
    const [image,setImage] = useState("");
    const fetchImages =  (name) => {
        const storageRef = ref(storage, `sponsers/${name}.png`);
        getDownloadURL(storageRef).then((dURL) => {
          setImage(dURL);
        }).catch((err) => {setImage("")});
      };

      useEffect(()=>{
        fetchImages(name);
      } , []);
  return (
    <motion.div
          initial={{ scale: 0 }}
          animate={{
            scale: 1,
            transition: {
              delay: 0.6 + index  ,
              duration: 0.3,
            },
          }}
          className="h-fit min-h-[10rem] border-b-4 border-red-600    rounded-md bg-[#18181B] text-white hover:text-black py-3 px-4 transition hover:-translate-y-2 flex justify-center items-center"
        >
          <a href={link} target="_blank" rel="noreferrer">
          <img src={image} alt="" className=" w-full h-[10rem] object-cover  py-3 relative" />
          </a>
        </motion.div>
  )
}

export default Sponser
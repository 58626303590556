import React, { useEffect, useState } from "react";
import "../../styles/Landing.css";
import { storage } from "../../firebase";
import Typewriter from "typewriter-effect";
import { getDownloadURL, ref } from "firebase/storage";
import { motion } from "framer-motion";
import useHead from "./useHead";
import { collection, getDocs, orderBy,query } from "firebase/firestore";
import { db } from "../../firebase";
import Sponser from "./Sponser";

function Landing() {
  const storageRef = ref(storage, `/landing 1.jpg`);
  
  const [image, setImage] = useState();

  const storageRef1 = ref(storage, `/landing 2.jpg`);
  const [aboutImage, setAboutImage] = useState("");
  const [sponsers, setSponsers] = useState([]);
  useHead(`E-KART`);
  const fetchSponsers = async () => {
    await getDocs(query(collection(db, "sponsers"),orderBy("name"))).then((ms) => {
      const data = ms.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setSponsers(data);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDownloadURL(storageRef).then((url) => {
      setImage(url);
    });
    getDownloadURL(storageRef1).then((url) => {
      setAboutImage(url);
    });
    fetchSponsers();
    
  }, []);
  const cardOutput = (icon, teamName, desc, no) => {
    return (
      <motion.div
        initial={{ scale: 0 }}
        animate={{
          scale: 1,
          transition: {
            delay: 0.6 + no,
            duration: 0.3,
          },
        }}
        className="h-fit min-h-[28rem] hover:border-b-4 border-red-600   hover:bg-gray-100 rounded-md bg-[#18181B] text-white hover:text-black py-3 px-4 transition hover:-translate-y-2 "
      >
        <div className=" w-full h-[20%] flex items-center ">
          <div className="w-[3rem] min-w-[3rem] h-[3rem] p-2 rounded-3xl border-none bg-gray-200 text-red-500  hover:text-red-500   text-xl flex items-center justify-center mr-4">
            {icon}
          </div>
          <h1 className="font-bold  max-md:text-xl max-lg:text-lg">
            {teamName}
          </h1>
        </div>
        <div className="bg-red-500 w-full h-[2px] my-2"></div>
        <p className="m-0 text-sm font-medium  text-justify">{desc}</p>
      </motion.div>
    );
  };
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "linear",
        },
      }}
      exit={{ x: window.innerWidth, opacity: 0 }}
      className="w-full h-fit bg-black "
    >
      <section className="relative w-full h-[40.9rem]">
        <img
          src={
            image
              ? image
              : "https://static.seekingalpha.com/cdn/s3/uploads/getty_images/1339047112/image_1339047112.jpg?io=getty-c-w1200"
          }
          alt=""
          className="w-full h-full z-0  object-cover"
        />
        <div className="bg-transparent p-8 z-10 w-full   h-full   border-none   absolute top-0 ">
          <div className="p-3 w-full  h-fit min-h-[19rem]  mt-6 ml-3">
            <h1 className="text-white max-sm:text-[1.1rem] sm:max-md:text-[1.4rem] font-bold text-[2.3rem]  ">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Welcome to ")
                    .pauseFor(1000)
                    // .deleteAll()
                    .typeString("E-KART Club ....")
                    .start();
                }}
              />
            </h1>
          </div>
        </div>
      </section>
      <section className=" w-full bg-black h-fit py-3 flex max-sm:flex-col ">
        <div className=" md:w-[40%] max-sm:w-full sm:max-md:w-1/2 h-full pt-8 px-6 pb-1  overflow-hidden">
          <h1 className="text-white font-semibold text-2xl">ABOUT US</h1>
          <div className="bg-red-500  w-full h-[2px] my-2"></div>
          <p className="text-white m-0 mt-3  leading-relaxed	text-justify	">
          Team Comprises of 100+ students from <span className="text-amber-400 font-[5000]	">Electrical Engineering</span> disciplines in Sri Krishna College of Engineering and Technology, Coimbatore with a shared passion for automotive engineering and motorsports to Revolutionize Electric Mobility. 
The team takes up the challenge of building an Electric car for various projects, competitions and works to become the most competent and cohesive team of engineers in the nation. 
The e-Kart club serves as a place of community for students to discuss, learn about, and working on projects related to Electric Vehicles. Members of the Electric Vehicles club are offered with a range of programs and activities.
          </p>
        </div>
        <div className=" w-[60%] max-sm:w-full max-md:w-1/2 h-full ">
          <img
            src={
              aboutImage
                ? aboutImage
                : "https://solutions.covestro.com/-/media/covestro/solution-center/story/images/electric-car-with-charging-station.jpg?w=1440&usecustomfunctions=1&rev=dcf86b6a13884e79b9adab19c855d01f&hash=D11E7E541A4F3F499A9466BC29EE5595D5860698"
            }
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
      </section>
      <section className="w-full bg-black h-fit flex flex-col p-6">
        <div className=" w-full text-center h-fit ">
          <h1 className="text-white font-semibold text-2xl mb-1">
            CREW LAYOUT
          </h1>
          <div className="bg-red-500 w-full h-[2px] my-2"></div>
        </div>
        <div className="scroll_manipulator   w-full h-fit bg-black grid  grid-cols-4 md:max-lg:grid-cols-3 sm:max-md:grid-cols-2 max-sm:grid-cols-1 gap-x-3  gap-y-4  p-4 overflow-y-scroll ">
          {cardOutput(
            <i class="fa-solid fa-car"></i>,
            "CHASSIS DESIGN",
            "Working as the backbone of the vehicle, a well-designed chassis will promote better performance of the vehicle, safety and comfort to the driver. Regulations must be followed to ensure a properly designed frame will protect the driver at all times. This being the case, there continues to be endless design possibilities which allow young engineers to design a chassis that can take a vehicles performance to its fullest extent.",
            0.2
          )}
          {cardOutput(
            <i class="fa-solid fa-gears"></i>,
            "TRANSMISSION",
            "Our powertrain team deals with the Electric Motor, Controller, Battery and other all supporting systems to get that power to the wheels, ending at the outer axle bearings. We are responsible for getting as much as power out of a 6kW PMSM, using tools as an diverse control strategies to our car for enhanced performance.",
            0.4
          )}
          {cardOutput(
            <i class="fa-solid fa-car-burst"></i>,
            "BRAKING",
            "We all know that pushing down on the brake pedal slows a car to a stop. But how does this happen? How does your car transmit the force from your leg to its wheels? How does it multiply the force so that it is enough to stop something as big as a car? That's what we are doing in our car! The brakes transmit the force to the tires using friction, and the tires transmit that force to the road using friction.",
            0.6
          )}
          {cardOutput(
            <i class="fa-solid fa-circle-radiation"></i>,
            "STEERING",
            "We, The Steering Team arrangement allows a driver to turn the front wheels of a vehicle using a hand - operated steering wheel positioned in front of the driver. The steering wheel is attached to a steering column, which is linked to rods, pivots and gears that allow the driver to change the direction of the front wheels.",
            0.8
          )}
          {cardOutput(
            <svg
              role="img"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Spring</title>
              <path
                d="M8.563 24c-1.839 0-4.435-.537-7.028-2.87l2.035-2.262c3.636 3.273 7.425 1.98 8.595.888.078-.079.156-.153.234-.23-3.83-.373-6.629-3.086-6.822-3.277-2.933-2.889-3.6-6.808-1.512-8.93s6.015-1.522 8.95 1.357c.257.246 3.116 3.052 3.677 6.605a6.776 6.776 0 002.727-5.426 6.62 6.62 0 00-1.995-4.791c-1.334-1.303-3.222-2.02-5.306-2.02V0c2.88 0 5.519 1.024 7.43 2.882 1.881 1.83 2.917 4.304 2.917 6.973a9.831 9.831 0 01-6.116 9.086c-.416 1.1-1.12 2.117-2.106 3.04-.97.905-2.865 1.908-5.28 2.01-.13.007-.262.009-.4.009zM7.283 9.077c-.425 0-.79.115-1.046.375-.749.762-.275 2.904 1.48 4.633l.008.009c.025.024 2.771 2.687 6.025 2.414v-.005c.015-2.873-2.808-5.597-2.837-5.625l-.02-.019C9.85 9.832 8.37 9.077 7.283 9.077Z"
                fill="#EF4444"
              ></path>
            </svg>,
            "SUSPENSION",
            "The suspension system is designed to maintain the tire's contact patch with the road in the optimal position during steady-state and transient conditions. The suspension must absorb bumps in the road to allow the sprung mass (chassis, driver, battery, motor, etc.), which is the components supported by the springs, to actuate under accelerations.",
            1
          )}
          {cardOutput(
            <i class="fa-solid fa-bolt"></i>,
            "ELECTRICAL",
            "We, The electrical team is responsible for the design and execution of the vehicle's electrical systems, including ADAS, ECU, dashboard, wire harness, safety systems, and data acquisition.",
            1.2
          )}
          {cardOutput(
            <i class="fa-solid fa-microchip"></i>,
            "INNOVATION",
            "We, The innovator is the person with a concern for “how” you will execute the big idea. They add value to the team in identifying and developing new methods, tools and ways to make a significant positive change. All great ideas require an execution team; the individuals that will work together to take your idea from conception to reality. We introducing in our car abot including The ADAS, ECU, CAN and dasboard.",
            1.4
          )}
          {cardOutput(
            <i class="fa-solid fa-money-bill-transfer"></i>,
            "COST AND BUDGET MANAGEMENT",
            "We, Business Development Team Seamlessly communicating the impact of frontline operations with the executive team, Helping your team to succeed, Delegating tasks to individuals,Identifying training needs,Implementing career development pathways and spotting career potential.",
            1.6
          )}
        </div>
        <section className="w-full bg-black h-fit flex flex-col p-6">
        <div className=" w-full text-center h-fit ">
          <h1 className="text-white font-semibold text-2xl mb-1">
             SPONSORS
          </h1>s
          <div className="bg-red-500 w-full h-[2px] my-2"></div>
        </div>
        <div className="scroll_manipulator   w-full h-fit bg-black  grid  grid-cols-4 md:max-lg:grid-cols-3 sm:max-md:grid-cols-2 max-sm:grid-cols-1 gap-x-3  gap-y-4  p-4 overflow-y-scroll ">
        {sponsers.map((sponser,index) => {
          return   <Sponser key={sponser.id} name={sponser.name} link={sponser.link} index={index}/>
        })}
      
        </div>
        </section>
        
      </section>
    </motion.div>
  );
}

export default Landing;

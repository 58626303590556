
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { removeAlert, setAlert } from '../../reducers/alert';
import {v4 as uuidv4} from "uuid"
function PrivateRoute({ children }) {
    const displayAlert = (msg,type) => {
        const id = uuidv4();
        dispatch(setAlert({msg,type,id}));
        setTimeout(()=> dispatch(removeAlert(id)),2000)
      }
      const dispatch = useDispatch()
        const auth = useSelector((state) => state.auth);
        return auth.isAuthenticated ? children :<>
        <Navigate to="/login" />
        {displayAlert("Please login to access the section","warning")}
        </>;
        
}


export default PrivateRoute;

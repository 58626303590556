import {configureStore ,combineReducers} from '@reduxjs/toolkit'
import authReducer from "./reducers/auth"
import alertReducer from "./reducers/alert"
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from "redux-persist"
import sessionStorage from 'redux-persist/es/storage/session' 

const persistConfig = {
    key : "app_state",
    storage: sessionStorage
}
const reducers = combineReducers({
    auth:authReducer ,
    alert :alertReducer,
    
});

const pReducer = persistReducer(persistConfig,reducers);
const store = configureStore({
    reducer: pReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
        devTools:false,
});

export default store ;

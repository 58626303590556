import React, { useEffect, useState } from "react";
import "../../styles/Header.css";
import { Link } from "react-router-dom";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { connect, useDispatch } from "react-redux";
import { removeUser } from "../../reducers/auth";
import { v4 as uuidv4 } from "uuid";
import { removeAlert, setAlert } from "../../reducers/alert";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  alpha,
  Container,
  Divider,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";

function Header({ auth }) {
  const [url, SetUrl] = useState("");

  const [teams, setTeams] = useState([]);
  const storageRef = ref(storage, `/ekart.png`);
  const displayAlert = (msg, type) => {
    const id = uuidv4();
    dispatch(setAlert({ msg, type, id }));
    setTimeout(() => dispatch(removeAlert(id)), 2000);
  };
  // const [position,setPosition] = useState(window.scrollY)
  const [view, setView] = useState(true);
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 4,

      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],

      "& .MuiMenuItem-root": {
        display: "flex",
        alignItems: "center",

        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleClose1();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const dispatch = useDispatch();
  const logOut = () => {
    const auth = getAuth();
signOut(auth).then(() => {
    dispatch(removeUser());
    displayAlert("logged out", "success");
}).catch((error) => {
        
});
   
  };
  const fetchEvents = async () => {
    await getDocs(collection(db, "teams")).then((eres) => {
      const data = eres.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTeams(data);
    });
  };
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setView(false);
    } else if (scrolled <= 300) {
      setView(true);
    }
  };
  // const handleScroll = () => {
  //   let moving = window.scrollY;
  //   setView(position > moving);
  //   setPosition(moving)
  // }
  window.addEventListener("scroll", toggleVisible);
  useEffect(() => {
    fetchEvents();
    getDownloadURL(storageRef).then((durl) => {
      SetUrl(durl);
    });
  }, []);
  return (
    <div
      className={
        view
          ? `sticky top-0 z-40 w-full h-16 bg-black p-2 flex items-center  transition-all duration-500 ease-out `
          : `sticky -top-[6rem] z-40 w-full h-16 bg-black p-2 flex items-center  transition-all duration-500 ease-out`
      }
    >
      <div className="w-[40%] max-md:w-[19rem] h-full  ">
        <Link className="h-full" to="/">
          <img
            src={url}
            alt="E-KART"
            className="h-full max-md:w-[25%] w-[18%]"
          />
        </Link>
      </div>
      <div
        id="demo-customized-button1"
        aria-controls={open1 ? "demo-customized-menu1" : undefined}
        aria-haspopup="true"
        aria-expanded={open1 ? "true" : undefined}
        variant="contained"
        onClick={handleClick1}
        className="cursor-pointer  max-lg:block hidden  absolute right-2 text-white lg:-top-full hover:text-red-500 transition px-4 py-3 "
      >
        {open1 ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
      </div>
      <div className=" w-[60%] h-full  max-md:w-40%   max-lg:hidden px-4 py-2 flex items-center  justify-end">
        <div
          className=" px-4 py-1 mx-3 text-center cursor-pointer hover:text-gray-400 cool_link hover:after:w-full text-md flex items-center justify-center"
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          onClick={handleClick}
        >
          TEAMS
        </div>
        <Link to="/members">
          <div className=" px-4 py-1 mx-3  cursor-pointer text-center hover:text-gray-400 cool_link hover:after:w-full text-md flex items-center justify-center">
            CREW
          </div>
        </Link>
        <Link to="/moments">
          <div className=" px-4 py-1 mx-3  cursor-pointer text-center hover:text-gray-400 cool_link hover:after:w-full text-md flex items-center justify-center">
            MOMENTS
          </div>
        </Link>
        <Link to="/blog">
          <div className=" px-4 py-1 mx-3  cursor-pointer text-center hover:text-gray-400 cool_link hover:after:w-full text-md flex items-center justify-center">
            BLOG
          </div>
        </Link>

        {auth?.token ? (
          <div
            className=" px-4 py-1 mx-3  cursor-pointer  text-center hover:text-gray-400 cool_link hover:after:w-full text-md flex items-center justify-center"
            onClick={logOut}
          >
            LOGOUT
          </div>
        ) : (
          <Link to="/login">
            <div className=" cursor-pointer hover:text-gray-400   px-4 py-1 mx-3 text-center cool_link hover:after:w-full text-md flex items-center justify-center">
              LOGIN{" "}
            </div>
          </Link>
        )}
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {teams.map((ev, index) => {
          return index !== teams.length - 1 ? (
            <Link to={`/team/${ev.teamName}`}>
              <Container key={ev.id} sx={{ maxWidth: "100%" }}>
                <MenuItem onClick={handleClose} disableRipple>
                  {ev.teamName}
                </MenuItem>
                <Divider sx={{ my: 2 }} />
              </Container>
            </Link>
          ) : (
            <Link to={`/team/${ev.teamName}`}>
              <Container>
                <MenuItem key={ev.id} onClick={handleClose} disableRipple>
                  {ev.teamName}
                </MenuItem>
              </Container>
            </Link>
          );
        })}
      </StyledMenu>
      <StyledMenu
        id="demo-customized-menu1"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button1",
        }}
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleClose1}
      >
        <Container
          onClick={
            handleClick
          }
          sx={{ maxWidth: "100%" }}
        >
          <MenuItem disableRipple>
            <div
              className=""
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
            >
              TEAMS
            </div>
          </MenuItem>
          <Divider sx={{ my: 2 }} />
        </Container>
        <Link to={`/members`}>
          <Container sx={{ maxWidth: "100%" }}>
            <MenuItem onClick={handleClose1} disableRipple>
              CREW
            </MenuItem>
            <Divider sx={{ my: 2 }} />
          </Container>
        </Link>
        <Link to={`/moments`}>
          <Container sx={{ maxWidth: "100%" }}>
            <MenuItem onClick={handleClose1} disableRipple>
              MOMENTS
            </MenuItem>
            <Divider sx={{ my: 2 }} />
          </Container>
        </Link>
        <Link to={`/blog`}>
          <Container sx={{ maxWidth: "100%" }}>
            <MenuItem onClick={handleClose1} disableRipple>
              BLOG
            </MenuItem>
            <Divider sx={{ my: 2 }} />
          </Container>
        </Link>

        {auth?.token ? (
          <Container sx={{ maxWidth: "100%" }}>
            <MenuItem
              onClick={() => {
                logOut();
                handleClose1();
              }}
              disableRipple
            >
              LOGOUT
            </MenuItem>
          </Container>
        ) : (
          <Link to={`/login`}>
            <Container sx={{ maxWidth: "100%" }}>
              <MenuItem onClick={handleClose1} disableRipple>
                LOGIN
              </MenuItem>
            </Container>
          </Link>
        )}
      </StyledMenu>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Header);

import { Alert } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import "../../styles/Alerts.css"
import {motion} from "framer-motion"
function Alerts({alerts}) {
  return (
    <div className='alert-wrapper'>
      {alerts.map((alert,index) => (
        <motion.div initial={{scale:0}} animate={{scale:1 , transition:{
          delay:(0.6+(index-(index*(60/100)))),
          duration:0.3
        }}} className="w-fit h-fit">
            <Alert key={index} severity={alert.type}>{alert.msg}</Alert>
        </motion.div>
        
           
      ))}
    </div>
  )
}
 const mapStateToProps = (state) => {
    return {
        alerts:state.alert,
    }
 }
export default connect(mapStateToProps)(Alerts);

import React, { useEffect, useState } from "react";
import "../../styles/Blogs.css";
import { request } from "graphql-request";
import BlogCard from "./BlogCard";
import PostWidget from "./PostWidget";
import useHead from "../layout/useHead";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
function Blogs() {
  const [posts, setPosts] = useState([]);
  useHead(`E-KART | BLOG`);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const results = await request(
        `${process.env.REACT_APP_GRAPHCMS_ENDPOINT}`,
        `
      query Assets {
        postsConnection {
          edges {
            node {
              author {
                bio
                name
                id
                photo {
                  url
                }
              }
              createdAt
              excerpt
              slug
              title
              featuredImage {
                url
              }
              categories {
                name
                slug
              }
            }
          }
        }
      }
        
      `
      );
      setPosts(results.postsConnection.edges);
    };
    fetchData();
  }, []);

  return (
    <motion.div
      initial={{ width: 0, opacity: 0 }}
      animate={{
        width: "100%",
        opacity: 1,
        transition: {
          duration: 0.4,
          ease: "easeInOut",
        },
      }}
      exit={{ x: window.innerWidth, opacity: 0 }}
      className="blogs_core min-h-screen px-[18rem] pt-[3rem] max-sm:px-[1rem] bg-black"
    >
      <div className="blogs_container">
        <Helmet>
          <meta
            name="description"
            content="To Share To Connect To create To inspire, Check out our blogs"
          />
        </Helmet>
        <div className="blog_container_header">
          <h1>BLOG</h1>
          <div className="w-full h-[2px] bg-red-500 rounded-md mb-2 mt-1"></div>
        </div>
        <div className="blog_container_content ">
          <div className="blog_posts ">
            {posts.map((post, index) => (
              <BlogCard no={index} post={post} key={post.title} />
            ))}
          </div>
          <div className="featured_cards ">
            <PostWidget />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Blogs;

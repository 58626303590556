// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import {getStorage} from "firebase/storage"
import { GoogleAuthProvider} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyDBu5gl9wSy-_iXahAXWQxmCO-n6Kb5EzQ",
  authDomain: "ekart-website-7b5e6.firebaseapp.com",
  projectId: "ekart-website-7b5e6",
  storageBucket: "ekart-website-7b5e6.appspot.com",
  messagingSenderId: "944676818904",
  appId: "1:944676818904:web:89705063f1c40358c1acad",
  measurementId: "G-T8Q3MQTK04"
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const db = getFirestore(app);
const provider = new GoogleAuthProvider();

export { db,storage,provider};

import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { db } from "../../firebase";
import {motion} from "framer-motion"
import "../../styles/Members.css";
import useHead from "../layout/useHead";
import Membercard from "./Membercard";
import { Helmet } from "react-helmet";
function Members() {
  const [members, setMembers] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [captains, setCaptains] = useState([]);
  const[hod,setHod] = useState([]);
  const membersRef = useRef();

  const fetchMembers = async () => {
    await getDocs(query(collection(db, "members"),orderBy("Designation"))).then((qs) => {
      const data = qs.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      setMembers(data);
    });
  };
  const fetchMentors = async () => {
    await getDocs(query(collection(db, "mentors"),orderBy("firstName"))).then((ms) => {
      const data = ms.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      setMentors(data);
    });
  };
  const fetchHod = async () => {
    await getDocs(query(collection(db, "hod"),orderBy("firstName"))).then((ms) => {
      const data = ms.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setHod(data);
    });
  };
  const fetchCaptains = async () => {
    await getDocs(query(collection(db, "captain"),orderBy("Designation"))).then((cs) => {
      const data = cs.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setCaptains(data);
    });
  };
  useHead(`E-KART | CREW`);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMembers();
    fetchCaptains();
    fetchMentors();
    fetchHod();
  }, []);
  return (
    <motion.div initial={{width:0,opacity:0}} animate={{width:"100%", opacity:1 , transition:{
      duration:0.4,
      ease:"easeInOut"
    }}} exit={{x:window.innerWidth , opacity:0}} className="bg-black w-full  relative top-0 min-h-screen  bg-contain px-16 max-sm:px-4 ">
      <div className=" w-full h-fit  pt-12">
        <Helmet>
          <meta name="description" content="A group of passionate Engineers and their mentors, who have geared
            up for their first ever venture of building E-Vehicles" />
        </Helmet>
        <div className=" mb-4">
          <h1 className="text-3xl font-bold text-white ">Meet the Crew</h1>
          <p className="m-0 my-1 text-white font-semibold ">
            A group of passionate Engineers and their mentors, who have geared
            up for their first ever venture of building E-Vehicles{" "}
          </p>

        </div>
        <div ref={membersRef} className=" py-4 ">
        <div className=" w-full flex flex-col justify-center pt-2">
            <div className="w-[35%] self-center text-center max-sm:flex max-sm:flex-col max-sm:items-center ">
              <p className="text-white text-2xl my-3 max-sm:mb-1 font-bold">HEAD OF THE DEPARTMENT</p>
              <div className="bg-red-500 w-full  h-[2px] mt-2 max-sm:mt-1"></div>
            </div>
            <div className="grid grid-cols-hod     p-3 justify-items-center">
              {hod.map((hod,index) => (
                <Membercard
                  additionalStyles={`max-sm:w-[13rem] sm:max-md:w-[12.5rem] md:w-[13.8rem] `}
                  no={(index-(index*(90/100)))}
                  key={hod.id}
                  firstname={hod.firstName}
                  lastname={hod.lastName}
                  email={hod.email}
                  department={hod.Department}
                  desig={hod.Designation}
                  degree={hod.Degree}
                  dob={hod.DOB}
                  phone={hod.Contact}
                  instagramLink={hod.instagramLink}
                  linkedinLink={hod.linkedinLink}
                />
              ))}
            </div>
          </div>
          <div className=" w-full flex flex-col justify-center pt-2">
            <div className="w-[35%] self-center text-center max-sm:flex max-sm:flex-col max-sm:items-center ">
              <p className="text-white text-2xl my-3 max-sm:mb-1 font-bold">FACULTY MENTORS</p>
              <div className="bg-red-500 w-full  h-[2px] mt-2 max-sm:mt-1"></div>
            </div>
            <div className="grid max-sm:grid-cols-2 gap-4 max-md:grid-cols-3 max-lg:grid-cols-4 grid-cols-6 self-center  w-full p-3 ">
              {mentors.map((mentor,index) => (
                <Membercard
                  no={(index-(index*(90/100)))}
                  key={mentor.id}
                  firstname={mentor.firstName}
                  lastname={mentor.lastName}
                  email={mentor.email}
                  department={mentor.Department}
                  desig={mentor.Designation}
                  degree={mentor.Degree}
                  dob={mentor.DOB}
                  phone={mentor.Contact}
                  instagramLink={mentor.instagramLink}
                  linkedinLink={mentor.linkedinLink}
                />
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col justify-center pt-2">
          <div className="w-[35%] self-center text-center max-sm:flex max-sm:flex-col max-sm:items-center ">
              <p className="text-white text-2xl my-3 max-sm:mb-1 font-bold">CAPTAINS</p>
              <div className="bg-red-500 w-full  h-[2px] mt-2 max-sm:mt-1"></div>
            </div>
            <div className="grid max-sm:grid-cols-2 gap-4 max-md:grid-cols-3 max-lg:grid-cols-4 grid-cols-6 self-center   w-full p-3 ">
              {captains.map((captain,index) => (
                <Membercard
                  additionalStyles={`uppercase`}
                  no={((mentors.length-(mentors.length *(90/100)))+(index-(index*(90/100))))}
                  key={captain.id}
                  firstname={captain.firstName}
                  lastname={captain.lastName}
                  email={captain.email}
                  department={captain.Department}
                  desig={captain.Designation}
                  dob={captain.DOB}
                  phone={captain.Contact}
                  instagramLink={captain.instagramLink}
                  linkedinLink={captain.linkedinLink}
                />
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col justify-center pt-2">
          <div className="w-[35%] self-center text-center max-sm:flex max-sm:flex-col max-sm:items-center ">
              <p className="text-white text-2xl my-3 max-sm:mb-1 font-bold">CREW</p>
              <div className="bg-red-500 w-full  h-[2px] mt-2 max-sm:mt-1"></div>
            </div>
            <div className="grid max-sm:grid-cols-2 gap-4 max-md:grid-cols-3 md:max-lg:grid-cols-4 grid-cols-6 lg:max-2xl:grid-cols-5 self-center   w-full p-3 ">
              {members.map((member,index) => (
                <Membercard
                additionalStyles={`uppercase`}
                  no={((mentors.length-(mentors.length *(90/100)))+(captains.length-(captains.length *(90/100)))+(index-(index*(90/100))))}
                  key={member.id}
                  firstname={member.firstName}
                  lastname={member.lastName}
                  email={member.email}
                  department={member.Department}
                  desig={member.Designation}
                  dob={member.DOB}
                  instagramLink={member.instagramLink}
                  linkedinLink={member.linkedinLink}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Members;

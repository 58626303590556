
import React, { useEffect} from "react";
import SendIcon from "@mui/icons-material/Send";
import { Link, useNavigate } from "react-router-dom";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";
import { useRef } from "react";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

function Footer() {
  const navigate = useNavigate();
  const storageRef = ref(storage, `/ekart.png`);
  const logoRef = useRef();
  useEffect(() => {
    getDownloadURL(storageRef).then((url) => {
      if(url){
        logoRef.current.src = url;

      }
    });
  }, []);
  return (
    <div className=" w-full h-fit bg-black border-t-2 border-red-500  pt-4  px-2    ">
      <div className="h-full  flex max-sm:flex-col ">
        <div className="  w-[37%] h-full max-sm:w-full ">
          <div className=" w-full h-[50%] sm:max-md:mb-5 xl:-mb-20 flex flex-col items-center ">
            <img
              ref={logoRef}
              className="w-full h-full -mb-4 object-contain"
              alt=""
              src=""
            />
            {/* <p className="text-white font-bold text-xl tracking-wide">
              Pedal to the Metal
            </p> */}
          </div>
          <div className=" w-full h-[50%] p-4 flex flex-col items-center ">
            <p className="text-white md:max-lg:text-[1.3rem]  mb-2 sm:max-md:text-[1.2rem] max-sm:text-[1.6rem] font-bold">
              JOIN OUR NEWSLETTER
            </p>
            <button
              onClick={() => {
                navigate("/newsletter_form");
              }}
              className="my-2 bg-[#212121] rounded-lg mt-3 px-4 py-3 text-white flex items-center font-bold  justify-center hover:bg-gray-100 min-w-[4rem] max-w-[12rem] hover:text-red-500 transition-colors "
            >
              REGISTER <SendIcon sx={{ marginLeft: "8px" }} />{" "}
            </button>
          </div>
        </div>
        <div className="flex flex-col max-sm:w-full  ">
          <div className="md:py-4  w-full max-md:w-full h-full max-sm:flex max-sm:flex-col max-sm:items-center  px-4 max-sm:px-5 py-2 max-sm:py-5  sm:max-md:px-7">
            <div className="w-full  max-sm:w-full max-sm:self-start  h-fit">
              <h1 className="text-white text-xl font-bold tracking-wider mb-[0.24rem]  ">
                ADDRESS
                <div className="w-full h-[2.5px] bg-red-500 "></div>
              </h1>
            </div>
            <div className="self-start">
              <p className="text-white font-normal text-[0.8rem] m-0 my-[0.1rem] xl:mb-2">
                E-KART Club, Department of EEE,
              </p>
              <p className="text-white font-normal text-[0.8rem] m-0 my-[0.1rem] xl:mb-2">
                SKCET, BK Pudur,
              </p>
              <p className="text-white font-normal text-[0.8rem] m-0 my-[0.1rem] xl:mb-2">
                Sugunapuram East,
              </p>
              <p className="text-white font-normal text-[0.8rem] m-0 my-[0.1rem] xl:mb-2">
                {" "}
                Kuniyamuthur,
              </p>
              <p className="text-white font-normal text-[0.8rem] m-0 my-[0.1rem] xl:mb-2">
                Tamil Nadu 641008
              </p>
            </div>
          </div>
          <div className=" md:py-4 w-full max-md:w-full h-full max-sm:flex max-sm:flex-col max-sm:items-center  px-4 max-sm:px-5  py-2 max-sm:py-5  sm:max-md:px-7">
            <div className="w-full  max-sm:w-full max-sm:self-start  h-fit">
              <h1 className="text-white text-xl font-bold tracking-wider mb-[0.24rem]  ">
                CONTACT
                <div className="w-full h-[2.5px] bg-red-500 "></div>
              </h1>
            </div>
            <div className="self-start">
            <p className="text-white font-normal text-[0.8rem] m-0 my-[0.1rem] xl:mb-2">
                <strong className="mr-1">PHONE :</strong>+91 9047898922<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                +91 9361833986
              </p>
              
              <p className="text-white font-normal text-[0.8rem] m-0 my-[0.1rem] xl:mb-2">
                <strong className="mr-1">EMAIL :</strong>ekartclub02@gmail.com
              </p>
            </div>
          </div>
        </div>

        <div className="  w-[45%] h-full sm:max-md:h-full   relative flex flex-col items-center justify-center  max-sm:w-full  ">
          <div className="w-full  max-sm:w-full max-sm:self-start pt-2 pb-4 px-4 h-fit">
            <h1 className="text-white text-xl font-bold tracking-wider   ">
              NAVIGATE
              <div className="w-full h-[2.5px] bg-red-500 "></div>
            </h1>
          </div>
          <div className="self-start">
            <Link to="/members">
              <div className=" px-4 py-1 mx-4 -mt-2  cursor-pointer max-sm:self-start my-2 max-sm:pl-0 hover:text-red-500  text-white transition-colors text-[1.25rem] flex items-center  justify-start max-sm:px-4">
                CREW
              </div>
            </Link>
            <Link to="/moments">
              <div className=" px-4 py-1 mx-4 -mt-2  cursor-pointer max-sm:self-start my-2 max-sm:pl-0 hover:text-red-500  text-white transition-colors text-[1.25rem] flex items-center justify-center max-sm:px-4">
                MOMENTS
              </div>
            </Link>
            <Link to="/blog">
              <div className=" px-4 py-1 mx-4 -mt-2  cursor-pointer max-sm:self-start my-2 max-sm:pl-0 hover:text-red-500  text-white transition-colors text-[1.25rem] flex items-center  justify-start max-sm:px-4">
                BLOG
              </div>
            </Link>
          </div>

          <div className=" w-full h-[13.5rem] p-2 rounded-md">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.3364581597284!2d76.95611171459058!3d10.937936992212041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85bb22369d571%3A0x72cc0bed93b5b2b6!2sSri%20Krishna%20College%20of%20Engineering%20and%20Technology!5e0!3m2!1sen!2sin!4v1673885989637!5m2!1sen!2sin"
              className="w-full h-full  rounded-md"
              allowFullScreen=""
              loading="lazy"
              title="map"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

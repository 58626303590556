import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "react-toastify/dist/ReactToastify.css";
import {
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { loadUser } from "../../reducers/auth";
import { v4 as uuidv4 } from "uuid";
import "../../styles/Login.css";
import { removeAlert, setAlert } from "../../reducers/alert";
import { provider, storage } from "../../firebase";
import useHead from "../layout/useHead";
import { getDownloadURL, ref } from "firebase/storage";
import { motion } from "framer-motion";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = getAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageRef = useRef();
  const displayAlert = (msg, type) => {
    const id = uuidv4();
    dispatch(setAlert({ msg, type, id }));
    setTimeout(() => dispatch(removeAlert(id)), 2000);
  };
  const loginUser = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((uc) => {
        dispatch(
          loadUser({
            token: uc._tokenResponse.refreshToken,
          })
        );
        displayAlert("Logged in", "success");
        navigate("/");
      })
      .catch((err) => {
        if (err.code === "auth/wrong-password") {
          displayAlert("Please check the password", "error");
        }
        if (err.code === "auth/user-not-found") {
          displayAlert("Please check the Email", "error");
        }
        if (err.code === "auth/invalid-email") {
          displayAlert("Please Enter an Proper Email", "error");
        }
      });
  };
  useHead(`E-KART | LOGIN`);
  const googleSign = () => {
    signInWithPopup(auth, provider)
      .then((res) => {
        const credential = GoogleAuthProvider.credentialFromResult(res);
       
        dispatch(
          loadUser({
            token: credential.accessToken,
          })
        );

        displayAlert("Logged in", "success");
        navigate("/");
      })
      .catch((err) => {
        if (err.code === "auth/popup-closed-by-user") {
          displayAlert("Authentication Cancelled", "warning");
        }
      });
  };
  const resetPassword = () => {
     sendPasswordResetEmail(auth,email).then(() => {
        displayAlert("Password reset mail is sent to your mailbox","success");

     }).catch((err) => {
       if(err.code === "auth/user-not-found"){
            displayAlert("Enter a Registered Email","warning");
       }
       if(err.code === "auth/missing-email"){
          displayAlert("Enter the required Email","warning");
       }
     });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getDownloadURL(ref(storage, "/login.png")).then((url) => {
      imageRef.current.src = url;
    });
  }, []);
  return (
    <motion.div
      initial={{ width: 0, opacity: 0 }}
      animate={{
        width: "100%",
        opacity: 1,
        transition: {
          duration: 0.4,
          ease: "easeInOut",
        },
      }}
      exit={{ x: window.innerWidth, opacity: 0 }}
      className=" flex h-screen"
    >
      <div className="flex  relative  flex-col justify-center w-full h-full bg-black  md:w-3/5 md:p-8">
        <button
          onClick={() => {
            navigate("/");
          }}
          className=" px-6 py-3  absolute top-3 left-2 hover:text-red-500 text-white  w-8 flex items-center justify-center transition-colors"
        >
          {" "}
          <ArrowBackIcon />
        </button>
        <div className="w-3/5  max-sm:shadow-lg h-fit max-md:p-8 max-md:w-[80%] md:bg-transparent self-center flex flex-col p-4 px-6  rounded-lg bg-[#212121] lg:w-3/4   md:w-full  ">
          <h1 className="text-2xl font-bold text-white">Hello there.. 👋</h1>
          <p className=" ml-0 text-red-400">
            Please enter your login Credentials
          </p>
          <div className=" my-2 mb-1 h-fit p-2 pl-0 flex flex-col font-bold font-sans  ">
            <label className="text-white">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="outline-none border border-zinc-300 p-2 rounded-md h-full  mt-1  "
            />
          </div>
          <div className=" my-2 mt-0 h-fit p-2 pl-0 flex flex-col font-bold font-sans  ">
            <label className="text-white">Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="outline-none rounded-md border border-zinc-300  h-full mt-1 p-2  "
            />
             <p className="mt-2 w-fit  text-red-500 mx-0 " onClick={resetPassword}>Forgot Password?</p>
             
            
          </div>
          <button
            onClick={loginUser}
            className=" flex items-center justify-center   rounded-lg w-20 p-2 text-white  bg-red-400 self-end mt-1 mr-2 "
          >
            SIGN IN
          </button>
          <div className="flex items-center w-full h-8">
            <div className="dividerl"></div>
            <div className="divider_text text-white">or</div>
            <div className="dividerl"></div>
          </div>
          <button
            onClick={googleSign}
            className=" max-md:border-2  border-red-400 h-12 flex items-center p-3 max-md:text-white rounded-lg self-center font-medium transition-shadow hover:shadow-md  md:bg-white w-fit  my-1"
          >
            <img
              className=" mr-2 h-full "
              src="https://cdn-teams-slug.flaticon.com/google.jpg"
              alt=""
            />
            Log in with Google
          </button>
          <div className="w-full ">
            <span>
              <p className="text-sm text-center text-white ">
                Don't Have an Account Yet?,
                <br />
                <Link className="text-blue-500 font-semibold " to="/register">
                  Create One Here
                </Link>
              </p>
            </span>
          </div>
        </div>
      </div>

      <div className="bg-black hidden h-full  md:block md:w-2/5">
        <img
          ref={imageRef}
          src=""
          alt=""
          className="w-full h-full overflow-hidden object-cover "
        />
      </div>
    </motion.div>
  );
}

export default Login;

import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Landing from "./components/layout/Landing";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Members from "./components/members/Members";
import Blogs from "./components/blog/Blogs";
import Layout from "./components/layout/Layout";
import Blog from "./components/blog/Blog";
import NewsletterForm from "./components/layout/NewsletterForm";
import PrivateRoute from "./components/auth/PrivateRoute";
import Alerts from "./components/layout/Alerts";
import EventComponent from "./components/event/EventComponent";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import {AnimatePresence} from "framer-motion";
import { useEffect, useState } from "react";
import Moments from "./components/moments/Moments";
import { Helmet } from "react-helmet";

function App() {
  const [teams,setTeams] = useState([]);
  const fetchEvents = async () => {
    await getDocs(collection(db, "teams")).then((eres) => {
      const data = eres.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      setTeams(data);
     
    });
  };
  const location = useLocation();
  
  useEffect(() => {
      fetchEvents();
  },[])
  return (

    <AnimatePresence>
      <Helmet>
         <meta name="keywords" content="ekart ,ekart skcet , ekart eee , ekart club  " />
      </Helmet>
      <Routes location={location}  key={location.pathname}>
          {teams.map(team => {
            return <Route path={`/team/${team.teamName}`} key={team.id} element={<Layout >
              <EventComponent title={team.teamName} desc={team.teamDesc} rules={team.eventRules} eventname={team.eventName} eventdesc={team.eventDesc} brochureStatus={team.brochure} insta={team.instagramLink} fb={team.facebookLink} yt={team.youtubeLink} linkedin={team.linkedinLink} twit={team.twitterLink} eventStatus={team.eventStatus} />
            </Layout>} /> 
          })}
          <Route path="/moments" element={<Layout >
            <Moments />
          </Layout>} />
        <Route
          path="/blog"
          element={
            <Layout>
              <PrivateRoute>
                <Blogs />
              </PrivateRoute>
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout>
              <Landing />
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route
          path="/members"
          element={
            <Layout>
              
                <Members />
              
            </Layout>
          }
        />

        <Route
          path="/newsletter_form"
          element={
            <Layout>
              <NewsletterForm />
            </Layout>
          }
        />
        <Route
          path="/post/:id"
          element={
            <Layout>
              <PrivateRoute>
              <Blog />
              </PrivateRoute>
            </Layout>
          }
        />
      </Routes>
      <Alerts />
    </AnimatePresence>
  );
}

export default App;

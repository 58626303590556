import { Avatar, Box, Modal } from "@mui/material";
import React, { useState } from "react";
import "../../styles/memberCard.css";
import InstagramIcon from "@mui/icons-material/Instagram";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { storage } from "../../firebase";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect } from "react";
import {motion} from "framer-motion"
function Membercard({
  additionalStyles,
  no,
  firstname,
  lastname,
  email,
  department,
  phone,
  desig,
  dob,
  instagramLink,
  linkedinLink,
  degree
}) {
  const [imageUrl, setImageurl] = useState("");
  const [visible, setVisible] = useState({ display: "none" });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "0.25rem",
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 2,
  };

  const fetchImages =  (name) => {
    const storageRef = ref(storage, `member_images/${name}.jpg`);
    getDownloadURL(storageRef).then((dURL) => {
      setImageurl(dURL);
    }).catch((err) => {setImageurl("")});
  };
  useEffect(() => {
    fetchImages(`${firstname} ${lastname}`);
  },[firstname,lastname])
  return (
    <motion.div initial={{scale:0}} animate={{scale:1 , transition:{
      delay:(0.2+no),
      duration:0.2
    }}}
      onMouseEnter={(e) => {
        setVisible({ display: "block" });
      }}
      onMouseLeave={(e) => {
        setVisible({ display: "none" });
      }}
      className={`${additionalStyles} bg-slate-200   cursor-pointer h-[19rem]  my-3 hover:shadow-custom hover:shadow-red-600 transition rounded-md`} 
    >
      <div className="w-full h-[75%] rounded-t-md">
        {
          imageUrl ?
          <img  src={imageUrl} alt="" className="w-full h-full rounded-t-md" /> : <img src="https://i.imgur.com/odiu6Gs.jpg" alt="" className="w-full h-full rounded-t-md" />
        }
      </div>
      <div className="w-full h-[25%]  pr-2 px-2  flex items-center justify-between">
        <div className="h-full max-sm:text-xs flex justify-center flex-col max-md:text-[0.745rem]   max-lg:text-[0.715rem] max-lg:font-semibold lg:max-xl:text-[0.73rem]">
          <h6 className="font-semibold  max-sm:mb-1">{`${firstname} ${lastname}`}</h6>
          <p className="m-0 text-gray-600  max-sm:font-semibold">{`${desig}`}</p>
        </div>
        <button
          onClick={handleOpen}
          className="transition flex items-center outline-none"
          style={visible}
        >
          <ArrowForwardIcon sx={{ color: "black" }} />
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style,display: "block", width: {xs:"95%",sm:"85%",lg:"35rem"},height:"fit-content",outline:"none"}}>
          <div className="w-full p-2 mb-2  h-[23rem]">
            <div className="flex items-center w-full pl-2 h-[40%] ">
              <Avatar
                sx={{ width: {md:"8rem",xs:"5rem"}, height: {md:"8rem",xs:"5rem"}, marginRight: "0.9rem", border:"2px solid #C9C9C9" ,objectFit:"cover" }}
                src={imageUrl}
                
              />
              <div className="w-[2px] max-sm:h-[90%]  h-full bg-gray-300 mr-2 max-sm:mr-1"></div>
              <div className=" h-full w-full flex flex-col ml-2 ">
                <div className=" mt-3 mb-4">
                  <h6 className="font-bold max-sm:text-sm text-gray-600">Name</h6>
                  <p className="m-0 max-sm:text-sm max-sm:mt-2 uppercase text-gray-500">{`${firstname} ${lastname}`}</p>
                </div>
                <div className="mb-3 ">
                  <h6 className="font-bold text-md max-sm:text-sm  text-gray-600">Email</h6>
                  <p className="m-0 max-sm:text-[0.69rem] max-sm:mt-2  text-ellipsis text-gray-500">{email}</p>
                </div>
              </div>
            </div>
            <div className="w-full h-[1.5px]  bg-gray-300 mb-1 mt-3 max-sm:mt-2"></div>
            <div className="w-full h-[60%]   flex ">
              <div className="h-full w-[50%]  flex flex-col items-start ">
                <div className=" mb-1">
                  <h6 className="font-bold text-md  text-gray-600">
                    Date of Birth
                  </h6>
                  <p className="m-0  text-gray-500">{dob}</p>
                </div>
                <div className=" mb-1">
                  <h6 className="font-bold text-md text-gray-600">
                    Department
                  </h6>
                  <p className="m-0 text-gray-500">{department}</p>
                </div>
                {
                  phone ?  <div className=" mb-1">
                  <h6 className="font-bold text-md text-gray-600">
                    Contact
                  </h6>
                  <p className="m-0 text-gray-500">{phone}</p>
                </div> : ""
                }
                {
                  degree ?  <div className=" mb-1">
                  <h6 className="font-bold text-md text-gray-600">
                    Degree
                  </h6>
                  <p className="m-0 text-gray-500">{degree}</p>
                </div> : ""
                }
                
              </div>
              <div className="h-full w-[50%]   p-2">
                <p className="m-0 font-semibold text-md ">SOCIAL LINKS</p>
                {instagramLink ?
                      <a href={`${instagramLink}`} target="_blank" rel="noreferrer">
                      <div className="mem-social-links-instagram transition flex items-center my-2 justify-start h-fit w-fit max-sm:px-2 max-sm:text-sm rounded-md py-2 px-3">
                          <InstagramIcon />
                          <p className="font-bold ">Instagram</p>
                      </div>
                        </a>
                :""}
                {linkedinLink ? <a href={`${linkedinLink}`} target="_blank" rel="noreferrer">
                <div className="mem-social-links-linkedin transition flex items-center my-2 justify-start h-fit w-fit max-sm:px-2 max-sm:text-sm  rounded-md py-2 px-3">
                    <LinkedInIcon />
                    <p className="font-bold ">Linkedin</p>
                </div>
                  </a>: ""}
                  
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </motion.div>
  );
}

export default Membercard;

import React from 'react'
import { useEffect } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import MyNewsletterForm from './MyNewsletterForm';
import {motion} from "framer-motion"
function NewsletterForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <motion.div initial={{width:0}} animate={{width:"100%" , transition:{
      duration:0.3,
    }}} exit={{x:window.innerWidth}} className="w-full h-screen flex items-center justify-center bg-black">
       <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_ENDPOINT}
         render={({subscribe,status,message}) => (
            <MyNewsletterForm
                   status={status}
                   message={message}
                   onValidated={formData => subscribe(formData)}
            />
         )}
       />
    </motion.div>
  )
}

export default NewsletterForm

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PostDetail from "./PostDetail";
import Author from "./Author";
import request from "graphql-request";
import useHead from "../layout/useHead";
import { motion } from "framer-motion";
function Blog() {
  const [post, setPost] = useState({});
  const { id } = useParams();
  useHead(`BLOGS | ${id}`);
  const getPostDetails = async () => {
    const results = await request(
      `${process.env.REACT_APP_GRAPHCMS_ENDPOINT}`,
      `
    query MyQuery($id:String!) {
      post(where: {slug: $id}) {
        author {
          name
          photo {
            url
          }
          bio
          id
        }
        createdAt
        excerpt
        slug
        title
        featuredImage {
          url
        }
        categories {
          name
          slug
        }
        content {
          raw
        }
      }
    }
    
    `,
      { id }
    );
    return results.post;
  };
  useEffect(() => {
    getPostDetails().then((result) => {
      setPost(result);
    });
  }, [id]);
  return (
    <motion.div
      initial={{ width: 0, opacity: 0 }}
      animate={{
        width: "100%",
        opacity: 1,
        transition: {
          duration: 0.4,
          ease: "easeInOut",
        },
      }}
      exit={{ x: window.innerWidth, opacity: 0 }}
      className="  w-full py-6 bg-black"
    >
      <div className="container  mx-auto px-10  h-full">
        <div className="w-full px-16 max-lg:px-4 max-md:px-2 max-sm:px-1  h-full">
          <PostDetail post={post} />
          <Author author={post?.author} />
        </div>
      </div>
    </motion.div>
  );
}

export default Blog;

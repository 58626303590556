import { Avatar } from "@mui/material";
import { gql, request } from "graphql-request";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function PostWidget({ categories, slug }) {
  const [relatedPosts, setRelatedPosts] = useState([]);
  useEffect(() => {
    getRecentPosts().then((result) => setRelatedPosts(result));
  }, [slug]);

  const getRecentPosts = async () => {
    const query = gql`
            query GetPostDetails() {
                posts(
                    orderBy: createdAt_ASC
                    last :3
                ){
                    title
                    featuredImage{
                        url
                    }
                    createdAt
                    slug
                }
            }
        `;
    const result = await request(
      `${process.env.REACT_APP_GRAPHCMS_ENDPOINT}`,
      query
    );
    return result.posts;
  };

  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "easeInOut",
        },
      }}
      className="bg-[#212121] shadow-lg  rounded-lg p-8 mb-8"
    >
      <h3 className="text-xl mb-8 text-gray-100  font-semibold border-b border-red-500 pb-4">
        Recent Posts
      </h3>
      {relatedPosts.map((post) => {
        return (
          <div key={post?.title} className="flex items-center w-full mb-4">
            <div className="w-16 flex-none">
              <Avatar
                alt={post?.title}
                sx={{ height: "65px", width: "65px" }}
                className="align-middle rounded-full"
                src={post?.featuredImage?.url}
              />
            </div>
            <div className="flex-grow ml-4">
              <p className="text-gray-100 font-xs">
                {moment(post?.createdAt).format("MMM DD, YYYY")}
              </p>
              <Link
                to={`/post/${post?.slug}`}
                key={post?.title}
                className=" text-sm text-gray-100"
              >
                {post?.title}
              </Link>
            </div>
          </div>
        );
      })}
    </motion.div>
  );
}

export default PostWidget;
